import { FunctionComponent } from 'react';
import { NJIconButton } from '@engie-group/fluid-design-system-react';
import './breadcrumb.scss';

interface PropTypes {
  path: {
    label: string;
    url?: string;
  }[];
}

const Breadcrumb: FunctionComponent<PropTypes> = ({ path }) => {
  const lastPath = path.pop();
  const intermediaryPathlist = path.map((path) => (
    <>
      <span className="ago-breadcrumb-separator">/</span>
      <div
        onClick={() => {
          window.location.href = `${path.url}`;
        }}
        className="ago-breadcrumb-path-link"
      >
        <span className="ago-text-regular-16">{path.label}</span>
      </div>{' '}
    </>
  ));

  return (
    <div className="">
      <div className="ago-breadcrumb-layout">
        <NJIconButton
          size="lg"
          href="/"
          label="breadcrumbIcon"
          icon="home"
          className="ago-breadcrumb-icon"
        />
        {intermediaryPathlist}
        <span className="ago-breadcrumb-separator">/</span>
        <span className="ago-breadcrumb-path ago-text-regular-16">{lastPath?.label}</span>
      </div>
    </div>
  );
};

export default Breadcrumb;
