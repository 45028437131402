enum StrapiRoutes {
  news = 'news',
  ecosystemStructure = 'ecosystem',
  ecosystem = 'uc-ecosystems',
  ecosystemFilters = 'filter',
  complianceHome = 'compliance-home',
  complianceForm = 'compliance-form',
  complianceSubmissions = 'cf-submissions',
  complianceEntites = 'cf-entities',
  adoptionProcess = 'adoption-processes',
  agoraHome = 'agora-home',
  oneDataTeam = 'one-data-team',
  dataManagement = 'data-management',
  globalDataDomains = 'global-data-domain',
  data4u = 'data4u',
  data4uProducts = 'catalogs',
  dataAtEngie = 'data-engie',
  dataAtEngieData = 'data-engie-data',
  dataAtEngieProducts = 'data-engie-products',
  domains = 'domains',
  filters = 'filter-catalogs',
  icons = 'icons',
  none = '',
  partnership = 'partnerships',
  platforms = 'platforms',
  secondFilterCatalogs = 'filter-catalog2s',
  weatherData = 'weather-data',
  wssPlans = 'wss-plans',
  yearBook = 'year-book',
}

export default StrapiRoutes;
